<script setup lang="ts">
import { RouterView, useRoute, useRouter } from 'vue-router'
import { useProfileStore } from '@/stores/profile'
import { useAuthStore } from '@/stores/auth'
import { nextTick, onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue'
import { useMagicKeys } from '@vueuse/core'
import { default as PDialog } from 'primevue/dialog'
import { default as PButton } from 'primevue/button'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { default as PConfirmDialog } from 'primevue/confirmdialog'
import TMain from '@/components/templates/TMain.vue'
import { storeToRefs } from 'pinia'
import BOrbits from '@/components/backgrounds/BOrbits.vue'
import { useSessionStore } from '@/stores/session'
import { loadPresets } from '@/assets/ts/presets'

const { initLogin } = useAuthStore()
const { isAuthenticated, isLoading: isAuth0Loading } = storeToRefs(useAuthStore())
const { putTrackingConsentBanner } = useProfileStore()
const { hasAnsweredTrackingConsentBanner, hasConsentedForTracking } = storeToRefs(useProfileStore())
const { startSessionMonitor } = useSessionStore()

// Get the router
const router = useRouter()
const route = useRoute()

// Cheats to get current version of the app
const keys = useMagicKeys()
const versionKeys = keys['Ctrl+Alt+w']
watch(versionKeys, (pressed) => {
  if (pressed) alert(`Version : ${__APP_NAME__}@${__APP_FULL_VERSION__}`)
})

// Loading local
const isLoading = ref(false)

// Consent Cookies
const showConsent = ref(false)
const consent = ref(false)

onMounted(async () => {
  await loadPresets()
  await startSessionMonitor()
  checkAuth0Cookie()
})

onBeforeUnmount(() => {
  showConsent.value = false
})

const checkAuth0Cookie = () => {
  if (undefined === route.name || route.name == 'home' || route.name == 'register') {
    if (document.cookie.includes('is.authenticated')) {
      router.replace({ name: 'logbook' })
    }
  }
}

watchEffect(async () => {
  if (route.query?.code || route.query?.state) {
    isLoading.value = true
  }

  if (isAuthenticated.value) {
    try {
      await initLogin()

      // Redirect to logbook if user is on home or register page
      if (undefined === route.name || route.name === 'home' || route.name === 'register') {
        await router.replace({ name: 'logbook' })
      }
      await nextTick(() => {
        showConsent.value = !hasAnsweredTrackingConsentBanner.value
        consent.value = hasConsentedForTracking.value
      })
    } catch (error) {
      // Do nothing
    } finally {
      isLoading.value = false
    }
  }
})

async function toConsent() {
  await putTrackingConsentBanner()
  showConsent.value = false
  consent.value = true
}
</script>

<template>
  <t-main :with-header="isAuthenticated ? !$route.meta.hideHeader : false">
    <div
      v-if="isLoading || isAuth0Loading"
      class="flex justify-center items-center h-screen w-screen"
      :class="$style['VLoading']"
    >
      <b-orbits />
      <p-progress-spinner />
    </div>
    <router-view v-else />
    <p-dialog
      id="dialog-consent"
      :visible="showConsent"
      :closable="false"
      modal
      :show-header="false"
      :show-footer="false"
      :draggable="false"
      content-style="display: flex; flex-direction: column; align-items: center; max-width:30rem;  border-radius: 10px; padding: 1.25rem; gap: 1.25rem;"
      :pt="{ mask: { style: 'padding: 1rem' } }"
    >
      <h6 class="heading medium">Utilisation des données</h6>
      <p class="italic text-center">
        En utilisant cette application, vous acceptez que vos données soient utilisées à des fins
        pédagogiques.
      </p>
      <p-button label="J'accepte" rounded severity="primary text-white" @click="toConsent" />
    </p-dialog>
    <p-confirm-dialog></p-confirm-dialog>
  </t-main>
</template>

<style module>
.VLoading {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: var(--linear-02);
    z-index: -1;
  }
}
</style>
