<script setup lang="ts">
import { default as PButton } from 'primevue/button'
import { default as PDrawer } from 'primevue/drawer'

import { useElementSize } from '@vueuse/core'
import { ref, watch } from 'vue'

// Refs
const iframeWrapperRef = ref<HTMLElement | null>(null)

// Variables
const { width, height } = useElementSize(iframeWrapperRef)

// Props
defineProps<{
  iframeURL: string
}>()

// Models
const drawerVisible = defineModel('visible', {
  type: Boolean,
  default: false
})

// Fonction pour mettre à jour les variables CSS
const updateCSSVariables = () => {
  if (width.value && height.value) {
    document.documentElement.style.setProperty('--drawer-width', `${width.value}px`)
    document.documentElement.style.setProperty('--drawer-height', `${height.value}px`)
  }
}

// Observer les changements de dimensions
watch([width, height], () => {
  if (drawerVisible.value) {
    updateCSSVariables()
  }
})

function toggleDrawer() {
  drawerVisible.value = !drawerVisible.value
}
</script>

<template>
  <div class="grid grid-cols-1">
    <p-drawer
      v-model:visible="drawerVisible"
      position="full"
      :modal="true"
      :blockScroll="true"
      :closable="true"
    >
      <template #container>
        <div :class="$style.drawerContainer">
          <div class="basis-1/12"></div>
          <div class="basis-10/12 justify-self-stretch self-stretch grow" ref="iframeWrapperRef">
            <div :class="$style.rotatedContainer">
              <iframe
                width="100%"
                height="100%"
                :src="iframeURL"
                title="Support Naboo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                sandbox="allow-same-origin allow-scripts"
              />
            </div>
          </div>
          <div :class="$style.closeContainer">
            <p-button
              type="button"
              @click="toggleDrawer"
              icon="pi pi-times"
              rounded
              outlined
            ></p-button>
          </div>
        </div>
      </template>
    </p-drawer>
  </div>
</template>

<style lang="scss" module>
.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Mode portrait */
@media screen and (orientation: portrait) {
  .drawerContainer {
    @apply flex flex-col justify-stretch h-full;
  }

  .rotatedContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: bottom left;
    transition:
      transform 0s ease,
      width 0.1s ease,
      height 0.1s ease;
  }

  .closeContainer {
    @apply basis-1/12 justify-center self-end content-center pr-2;
  }

  .rotatedContainer {
    width: var(--drawer-height);
    height: var(--drawer-width); /* Hauteur du Drawer devient la largeur de l'iframe */
    transform: rotate(90deg) translateX(-110%);
  }
}

/* Mode paysage */
@media screen and (orientation: landscape) {
  .drawerContainer {
    @apply flex flex-row justify-stretch h-full;
  }

  .rotatedContainer {
    @apply w-full h-full;
    transition:
      transform 0.1s ease,
      width 0.1s ease,
      height 0.1s ease;
  }

  .closeContainer {
    @apply basis-1/12 justify-self-center text-center pt-2;
  }
}
</style>
